
























































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import UserService from '@/services/UserService';

@Component
export default class UserSelfservice extends mixins(Toasts) {
  protected breadcrumbs: { text: string; to?: string; active?: boolean }[] | null = null;
  protected oldPassword = '';
  protected newPassword = '';
  protected newPasswordagain = '';

  mounted(): void {
    this.breadcrumbs = [
      { text: 'Home', to: '/' },
      { text: 'User Selfservice', to: '/selfservice', active: true },
    ];
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  protected changePassword(): void {
    this.setLoading(true);
    if (this.newPassword === this.newPasswordagain) {
      UserService.updatePassword(this.oldPassword, this.newPassword)
        .then(() => {
          this.setLoading(false);
          this.oldPassword = '';
          this.newPassword = '';
          this.newPasswordagain = '';
          this.showToast('Password updated', 'New password has been updated.', 'success');
        })
        .catch((backendError) => {
          this.setLoading(false);
          if (backendError.response.status === 403) {
            this.showToast('Action denied', 'You do not have the required rights.', 'danger');
          } else {
            this.showToast(
              'Failed to update',
              'Could not update password. ' + backendError.response.data.message,
              'danger'
            );
          }
        });
    } else {
      this.setLoading(false);
      this.showToast('Action denied', 'Passwords do not match.', 'danger');
    }
  }
}
